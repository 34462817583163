import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthContext';
import { ToastContainer } from 'react-toastify';

import Navbar from './components/navbar';
import Header from './components/header';
import Agenda from './components/agenda';
import Footer from './components/footer';
import Login from './screens/Login';
import Admin from './screens/Admin';
import Blagajna from './screens/Blagajna';

import './css/style.css';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
  const { user } = useAuth();

  return (
    <main>
      {user && <Navbar />}
      {user && <Header />}
      <div className="home-main">
        <div className="content">
          <Agenda />
        </div>
        <ToastContainer />
      </div>
    </main>
  );
};

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
};

const MainContent = () => {
  return (
    <div className="main-content">
      <Routes>
        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/login" element={<Login />} />
        <Route path="/blagajna" element={<PrivateRoute><Blagajna /></PrivateRoute>} />
        <Route path="/admin" element={<PrivateRoute adminOnly={true}><Admin /></PrivateRoute>} />
      </Routes>
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <MainContent />
      </AuthProvider>
    </Router>
  );
}

export default App;
