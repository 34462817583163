import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const App = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [data, setData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/skripte/pacijenti.php`); // Zamijeni s tvojim API endpointom
                setData(response.data);
                console.log('Pacijenti Response:', response.data);
            } catch (error) {
                console.error('Greška prilikom dohvaćanja podataka:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, []);

    // Funkcija za otvaranje modala 
    const openModal = (user) => {
        setSelectedUser(user);
        setModalIsOpen(true);
    };

    // Funkcija za zatvaranje modala
    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedUser(null);
    };

    const modalStyle = {
        content: {
            width: '80%',
            height: '80%',
            border: '1px solid #212529',
            background: '#F7F6F2',
            overflow: 'auto',
            borderRadius: '10px',
            outline: 'none',
            padding: '20px',
            position: 'fixed',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',

        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
        }
    }

    return (
        <div>
            <h1>Baza pacijenata</h1>
            <table className="employees-table">
                <thead>
                    <tr>
                        <th>Ime</th>
                        <th>Prezime</th>
                        <th>Email</th>
                        <th>Broj mobitela</th>
                        <th>Potrošnja</th>
                        <th>Akcija</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((user) => (
                        <tr key={user.id}>
                            <td>{user.ime}</td>
                            <td>{user.prezime}</td>
                            <td>{user.email}</td>
                            <td>{user.mobitel}</td>
                            <td>{user.potrosnja}</td>
                            <td>
                                <button
                                    className="action-button-2"
                                    onClick={() => openModal(user)}
                                >
                                    Detalji
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal za prikaz korisničkih detalja */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Detalji korisnika"
                style={modalStyle}
            >
                <h2>Detalji korisnika</h2>
                {selectedUser && (
                    <div>
                        <p><strong>Ime i prezime:</strong> {selectedUser.ime} {selectedUser.prezime}</p>
                        <p><strong>Email:</strong> {selectedUser.email}</p>
                        <p><strong>Broj mobitela:</strong> {selectedUser.mobitel}</p>
                        <p><strong>Potrošnja:</strong> {selectedUser.potrosnja}</p>
                        <p><strong>Povijest usluga:</strong></p>
                    </div>
                )}
                <button className="primary-button" onClick={closeModal}>Zatvori</button>
            </Modal>
        </div >
    );
};

export default App;
