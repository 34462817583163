import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { useAuth } from './AuthContext';
import axios from "axios";
import moment from "moment";
import { Modal, Box, Button, TextField, MenuItem, Dialog, Select, FormControl, InputLabel, List, ListItem, ListItemText, DialogActions, DialogContent, DialogTitle, Autocomplete } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import "react-big-calendar/lib/css/react-big-calendar.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Kreiranje lokalizatora koristeći moment
const localizer = momentLocalizer(moment);

const resources = [
  { id: 1, resourceTitle: "Soba 1" },
  { id: 2, resourceTitle: "Soba 2" },
  { id: 3, resourceTitle: "Soba 3" },
  { id: 4, resourceTitle: "Soba 4" },
];

function CustomEvent({ event }) {
  return (
    <div className="custom-event">
      <div className="event-title">
        {event.pacijentIme} {event.pacijentPrezime}

        {/* Ako je pacijent zaprimljen, prikazujemo tekst "Zaprimljen" zelenom bojom */}
        {event.isZaprimljen && (
          <span style={{ color: 'green', marginLeft: '8px' }}>
            Zaprimljen
          </span>
        )}
      </div>
      <div className="event-time" style={{ display: 'flex', alignItems: 'center' }}>
        <AccessTimeIcon style={{ fontSize: '20px', marginRight: '8px' }} /> {/* Definiraj veličinu ikone i razmak */}
        {moment(event.start).format("HH:mm")} - {moment(event.end).format("HH:mm")}
      </div>

      <div className="event-room" style={{ display: 'flex', alignItems: 'center' }}>
        <DescriptionIcon style={{ fontSize: '20px', marginRight: '8px' }} /> {/* Definiraj veličinu ikone i razmak */}
        {event.device} | {event.productName}
      </div>

      <div className="event-doctor" style={{ display: 'flex', alignItems: 'center' }}>
        <AccountCircleIcon style={{ fontSize: '20px', marginRight: '8px' }} /> {/* Definiraj veličinu ikone i razmak */}
        {event.doctor1}
        {event.doctor2 && `, ${event.doctor2}`}
      </div>

    </div>
  );
}

function MyCalendar() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { user } = useAuth();
  const [events, setEvents] = useState([]);
  const [products, setProducts] = useState([]); // Proizvodi iz baze
  const [openAddModal, setOpenAddModal] = useState(false); // Modal za dodavanje događaja
  const [openSelectedEventModal, setOpenSelectedEventModal] = useState(false); // Modal za odabrani događaj
  const [selectedEvent, setSelectedEvent] = useState(null); // Čuva odabrani događaj
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editMode, setEditMode] = useState(false); // Stanje koje prati je li uređivanje omogućeno
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [originalEvent, setOriginalEvent] = useState(null);
  const [openPatientModal, setOpenPatientModal] = useState(false);
  const [isZaprimljen, setIsZaprimljen] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [patients, setPatients] = useState([]);
  const [isTimeInRange, setIsTimeInRange] = useState(false);
  const [newEvent, setNewEvent] = useState({
    username: "",
    pacijent_id: "",
    start: "",
    end: "",
    doctor_1: "",
    doctor_2: "",
    productId: "",
  });
  const [patientData, setPatientData] = useState({
    ime: "",
    prezime: "",
    email: "",
    mobitel: "",
  });

  // Funkcija za proveru da li je pacijent zaprimljen
  const checkZaprimanje = async (eventId) => {
    try {
      const response = await fetch(`${apiUrl}/skripte/zaprimiPacijenta.php?action=check&event_id=${eventId}`);

      // Čitanje odgovora kao tekst (radi dijagnostike)
      const textResponse = await response.text();
      console.log("Odgovor iz PHP-a:", textResponse); // Ispis odgovora u konzolu

      // Pokušaj parsiranja odgovora u JSON
      const data = JSON.parse(textResponse);

      if (data.exists) {
        console.log("Pacijent je već zaprimljen.");
        setIsZaprimljen(true);
      } else {
        console.log("Pacijent nije zaprimljen.");
        setIsZaprimljen(false);
      }
    } catch (error) {
      console.error("Greška prilikom provjere eventa:", error);
    }
  };

  // Kada se otvori modal, proveri da li je pacijent već zaprimljen
  useEffect(() => {
    if (selectedEvent && selectedEvent.id) {
      checkZaprimanje(selectedEvent.id);
    }
  }, [selectedEvent]);

  // Funkcija za zaprimanje pacijenta (unosi podatke u bazu)
  const handleZaprimanjePacijenta = async () => {
    try {
      const doctor1 = selectedEvent.doktori && selectedEvent.doktori[0] ? selectedEvent.doktori[0] : null;
      const doctor2 = selectedEvent.doktori && selectedEvent.doktori[1] ? selectedEvent.doktori[1] : null;

      const response = await fetch(`${apiUrl}/skripte/zaprimiPacijenta.php?action=zaprimanje`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pacijenti_id: selectedEvent.pacijent_id,
          proizvodi_id: selectedEvent.productId,
          users_id_1: doctor1,
          users_id_2: doctor2,
          zaprimka: new Date().toISOString(),
          event_id: selectedEvent.id,
        }),
      });

      // Ispis odgovora u tekstualnom obliku
      const textResponse = await response.text();
      console.log("Odgovor iz PHP-a:", textResponse);  // Ovdje se ispisuje cijeli odgovor
      console.log("HTTP status:", response.status);

      // Parsiraj JSON ako je sve u redu
      const data = JSON.parse(textResponse);

      if (response.ok) {
        toast.success("Pacijent uspješno zaprimljen!");
        handleCloseSelectedEventModal();
        fetchEvents();
        setIsZaprimljen(true); // Sakrij gumb nakon zaprimanja
      } else {
        const errorData = await response.json(); // Parsiranje JSON greške
        alert("Greška: " + errorData.message);
      }
    } catch (error) {
      console.error("Greška prilikom zaprimanja pacijenta:", error);
    }
  };


  const formatTime = (date) => {
    return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  };

  // Pacijent modal
  const handlePacijent = async () => {
    try {
      const response = await fetch(`${apiUrl}/skripte/pacijenti.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(patientData),
      });

      const data = await response.json();
      if (response.ok) {
        fetchPatients();
        toast.success("Pacijent uspješno dodan!");
        setPatientData({
          ime: "",
          prezime: "",
          email: "",
          mobitel: "",
        });
      } else {
        alert("Greška: " + data.message);
      }
    } catch (error) {
      console.error("Greška prilikom slanja podataka:", error);
    }
  };

  const handleOpenPatientModal = () => {
    setOpenPatientModal(true);
  };

  const handleClosePatientModal = () => {
    setOpenPatientModal(false);
  };

  const handlePatientInputChange = (e) => {
    const { name, value } = e.target;
    setPatientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value) => {
    setPatientData((prevData) => ({
      ...prevData,
      mobitel: value,  // Postavljamo broj mobitela u state
    }));
  };

  const handleSubmitPatient = () => {
    handlePacijent(); // Poziv funkcije za obradu pacijenta
    handleClosePatientModal(); // Zatvori modal nakon obrade
  };

  //Mobilna verzija
  const [selectedRoom, setSelectedRoom] = useState(resources[0].id); // Zadano je prva soba
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (user && user.username) {
      setNewEvent(prevState => ({
        ...prevState,
        username: user.username, // Postavi username kad je dostupan
      }));
    }
  }, [user]);

  // Funkcija za dohvaćanje svih događaja iz baze
  const fetchEvents = () => {
    axios.get(`${apiUrl}/skripte/agenda.php?action=fetch`).then((response) => {
      console.log("Dohvaćeni podaci nakon spremanja:", response.data);
      const formattedEvents = response.data.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        id: event.id,
        device: event.device,
        productName: event.productName,
        doktori: [event.users_id_1, event.users_id_2].filter(Boolean), // Koristi string s doktorima
        doctor_1: event.doctor1,
        doctor_2: event.doctor2,
        isZaprimljen: event.isZaprimljen,
      }));
      setEvents(formattedEvents);
    });
  };

  useEffect(() => {
    fetchEvents();

    // Postavi event listener za praćenje promjena širine prozora
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize); // Očisti event listener pri uništavanju komponente
    };
    //eslint-disable-next-line
  }, []);

  // Dohvaćanje podataka iz baze podataka (GET zahtjev)
  useEffect(() => {
    // Dohvati događaje i proizvode
    fetchEvents();
    axios.get(`${apiUrl}/skripte/agenda.php?action=fetchProducts`).then((response) => {
      setProducts(response.data); // Postavljanje proizvoda u state
    });
    //eslint-disable-next-line
  }, []);

  const fetchDoctors = () => {
    axios.get(`${apiUrl}/skripte/agenda.php?action=fetchDoctors`)
      .then((response) => {
        setDoctors(response.data);
      })
      .catch((error) => {
        console.error('Error fetching doctors:', error);
      });
  };

  const fetchPatients = () => {
    // Učitaj pacijente iz baze
    axios.get(`${apiUrl}/skripte/agenda.php?action=fetchPatients`)
      .then((response) => {
        setPatients(response.data);  // Postavi pacijente u state
      })
      .catch((error) => {
        console.error("Greška prilikom učitavanja pacijenata:", error);
      });
  }

  useEffect(() => {
    fetchPatients();
  }, []);

  // Pozovi ovu funkciju unutar useEffect kako bi doktori bili učitani prilikom rendera komponente
  useEffect(() => {
    fetchDoctors();  // Pozivanje funkcije za povlačenje doktora
  }, []);

  // Funkcija za provjeru da li je vrijeme unutar raspona 12:00 - 14:00
  const checkTimeInRange = (start, end) => {
    const startHour = moment(start).format('HH:mm');
    const endHour = moment(end).format('HH:mm');
    const rangeStart = '12:00';
    const rangeEnd = '14:00';

    return (startHour >= rangeStart && endHour <= rangeEnd);
  };

  useEffect(() => {
    if (newEvent.start && newEvent.end) {
      const inRange = checkTimeInRange(newEvent.start, newEvent.end);
      setIsTimeInRange(inRange);
    }
  }, [newEvent.start, newEvent.end]);

  // Funkcija za otvaranje i zatvaranje modala za dodavanje događaja
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    resetForm(); // Resetiraj formu prilikom zatvaranja
  };

  const handleCloseSelectedEventModal = () => {
    setOpenSelectedEventModal(false);
    setSelectedEvent(null); // Resetiraj odabrani događaj
    setEditMode(false); // Resetiraj način uređivanja
  };

  // Funkcija za rukovanje klikom na događaj
  const handleSelectEvent = async (event) => {
    // Kreiramo kopiju event objekta kako bi izbegli promene u originalnom objektu
    const selectedEventCopy = { ...event };

    // Postavi izabrani događaj
    setSelectedEvent({
      ...selectedEventCopy,
      username: selectedEventCopy.username || user.username,
    });

    // Postavi originalni događaj za eventualne izmene kasnije
    setOriginalEvent(selectedEventCopy);

    try {
      await checkZaprimanje(selectedEventCopy);  // Prosleđujemo kopirani event u checkZaprimanje

      // Nakon što je provera završena, otvori modal
      setOpenSelectedEventModal(true); // Otvori modal
      setEditMode(false); // Onemogući uređivanje na početku
    } catch (error) {
      console.error("Greška prilikom provere zaprimanja pacijenta:", error);
    }
  };

  // Funkcija za upravljanje promjenama inputa
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Ažuriraj selectedEvent s novom vrijednošću
    setSelectedEvent((prevEvent) => {
      const updatedEvent = {
        ...prevEvent,
        [name]: value,
        username: prevEvent.username,  // Zadrži username iz prethodnog stanja
      };

      // Provjeri vrijeme nakon ažuriranja
      const timeInRange = checkTimeInRange(updatedEvent.start, updatedEvent.end);

      // Ažuriraj isTimeInRange
      setIsTimeInRange(timeInRange);

      // Ako nije unutar raspona, uklanjamo drugog doktora iz stanja
      if (!timeInRange) {
        updatedEvent.doktori[1] = null;  // Praznimo vrijednost za drugog doktora
      }

      return updatedEvent;
    });
  };


  // Funkcija za resetiranje forme
  const resetForm = () => {
    setNewEvent({
      username: "",
      pacijent_id: "",
      start: "",
      end: "",
      id: "",
      productId: "",
      doctor_1: "",
      doctor_2: "",
    });
  };

  // Funkcija za validaciju unosa za ažuriranje
  const validateInputsUpdates = async () => {
    // Provera da li su sva polja popunjena
    if (!selectedEvent.pacijent_id || !selectedEvent.start || !selectedEvent.end || !selectedEvent.productId || !selectedEvent.doktori[0]) {
      toast.error("Sva polja moraju biti popunjena!");
      return false;
    }

    // Provera da li je početno vreme pre završnog vremena
    const start = new Date(selectedEvent.start);
    const end = new Date(selectedEvent.end);

    if (start >= end) {
      toast.error("Vrijeme početka mora biti prije vremena završetka!");
      return false;
    }

    // Provera da li su početak i kraj unutar istog dana
    if (start.toDateString() !== end.toDateString()) {
      toast.error("Vrijeme početka i vrijeme završetka moraju biti unutar istog dana!");
      return false;
    }

    // Provera da li je vrijeme unutar radnog vremena (10:00 - 20:00)
    const startHour = start.getHours();
    const endHour = end.getHours();

    if (startHour < 10 || startHour >= 20) {
      toast.error("Vrijeme početka mora biti između 10:00 i 20:00!");
      return false;
    }

    if (endHour < 10 || endHour >= 20) {
      toast.error("Vrijeme završetka mora biti između 10:00 i 20:00!");
      return false;
    }

    // Provjera preklapanja termina za oba doktora (ako postoji drugi doktor)
    try {
      const response = await fetch(`${apiUrl}/skripte/validacija.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          start: selectedEvent.start,
          end: selectedEvent.end,
          productId: selectedEvent.productId,
          doctors: selectedEvent.doktori.filter(Boolean),  // Proveravamo oba doktora ako su prisutni
          event_id: selectedEvent.id,
          pacijent_id: selectedEvent.pacijent_id
        }),
      });

      const data = await response.json();

      // Ako postoji preklapanje, prikazujemo grešku
      if (!data.success) {
        toast.error(data.message || "Termin je zauzet! Odaberite drugo vrijeme.");
        return false;
      }

    } catch (error) {
      console.error("Greška prilikom provjere zauzetosti:", error);
      toast.error("Došlo je do greške prilikom provjere zauzetosti.");
      return false;
    }

    // Ako prođe sve validacije, vraćamo true
    return true;
  };


  const validateInputs = async () => {
    // Provjeri jesu li sva obavezna polja popunjena
    if (!newEvent.pacijent_id || !newEvent.start || !newEvent.end || !newEvent.productId || (!newEvent.doctor_1 && !newEvent.doctor_2)) {
      toast.error("Sva polja moraju biti popunjena, uključujući barem jednog doktora!");
      return false;
    }

    // Provjera da li je barem jedan doktor odabran
    if (!newEvent.doctor_1 && !newEvent.doctor_2) {
      toast.error("Morate odabrati barem jednog doktora!");
      return false;
    }

    // Provjera da li su odabrani doktori ispravni (ne prazni stringovi)
    if (newEvent.doctor_1 === "" || (newEvent.doctor_2 !== "" && newEvent.doctor_2 === newEvent.doctor_1)) {
      toast.error("Morate odabrati različite doktore ili samo jednog.");
      return false;
    }

    // Provjera da li je vrijeme početka prije vremena završetka
    if (new Date(newEvent.start) >= new Date(newEvent.end)) {
      toast.error("Vrijeme početka mora biti prije vremena završetka!");
      return false;
    }

    const start = new Date(newEvent.start);
    const end = new Date(newEvent.end);

    // Provjera da li je vrijeme unutar radnog vremena (10:00 - 20:00)
    const startHour = start.getHours();
    const endHour = end.getHours();

    if (startHour < 10 || startHour >= 20) {
      toast.error("Vrijeme početka mora biti između 10:00 i 20:00!");
      return false;
    }

    if (endHour < 10 || endHour >= 20) {
      toast.error("Vrijeme završetka mora biti između 10:00 i 20:00!");
      return false;
    }

    // Provjera preklapanja termina za doktore
    try {
      const response = await fetch(`${apiUrl}/skripte/validacija.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          start: newEvent.start,
          end: newEvent.end,
          pacijent_id: newEvent.pacijent_id,
          productId: newEvent.productId,
          doctors: [newEvent.doctor_1, newEvent.doctor_2].filter(Boolean) // Filtriraj i pošalji samo odabrane doktore
        }),
      });

      const data = await response.json();

      if (!data.success) {
        toast.error(data.message || "Termin je zauzet! Odaberite drugo vrijeme.");
        return false;
      }

    } catch (error) {
      console.error("Greška pri provjeri zauzetosti:", error);
      toast.error("Došlo je do greške prilikom provjere zauzetosti.");
      return false;
    }

    // Provjera da li su početak i kraj u istom danu
    const startDate = new Date(newEvent.start);
    const endDate = new Date(newEvent.end);
    if (startDate.toDateString() !== endDate.toDateString()) {
      toast.error("Vrijeme početka i vrijeme završetka moraju biti unutar istog dana!");
      return false;
    }

    return true;
  };

  // Funkcija za slanje podataka na backend (dodavanje novog događaja)
  const handleSubmitEvent = async () => {
    // Ovdje koristimo await za asinkronu validaciju
    const isValid = await validateInputs(); // Čekamo rezultat validateInputs funkcije

    // Ako validacija nije prošla, prekinemo izvršavanje
    if (!isValid) {
      return;
    }

    console.log("newEvent prije slanja:", newEvent);

    const formattedEvent = {
      ...newEvent,
      start: moment(newEvent.start).format("YYYY-MM-DD HH:mm:ss"),
      end: moment(newEvent.end).format("YYYY-MM-DD HH:mm:ss"),
    };

    // Šaljemo zahtjev za dodavanje događaja samo ako je validacija prošla
    try {
      const response = await axios.post(`${apiUrl}/skripte/agenda.php?action=create`, formattedEvent);

      // Nakon što se događaj uspješno kreira, ažurirajte kalendar
      if (response.data.success) {
        console.log("Događaj uspješno spremljen:", response.data);
        fetchEvents();
        toast.success("Termin uspješno dodan!");
        handleCloseAddModal(); // Zatvaranje modala nakon kreiranja događaja
      } else {
        toast.error("Došlo je do greške prilikom dodavanja događaja!");
        console.error(response.data.error);
      }
    } catch (error) {
      toast.error("Greška prilikom komunikacije s poslužiteljem!");
      console.error("Greška prilikom dodavanja događaja:", error);
    }
  };

  // Funkcija za omogućavanje uređivanja
  const handleEdit = () => {
    setEditMode(true); // Omogući uređivanje
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  // Funkcija za brisanje događaja
  const handleDeleteConfirmed = () => {
    if (selectedEvent?.id) {

      axios.post(`${apiUrl}/skripte/agenda.php?action=delete`, { id: selectedEvent.id }).then((response) => {
        if (response.data.success) {
          fetchEvents(); // Ponovno dohvaćanje događaja
          toast.success("Događaj uspješno izbrisan!");
          handleCloseSelectedEventModal(); // Zatvori modal
          handleCloseDeleteDialog();
        } else {
          toast.error("Greška prilikom brisanja događaja.");
        }
      });
    } else {
      toast.error("ID događaja nije definiran.");
    }
  };

  // Funkcija za ažuriranje događaja
  const handleUpdate = async () => {
    // Ovdje koristimo await za asinkronu validaciju
    const isValid = await validateInputsUpdates(); // Čekamo rezultat validateInputs funkcije

    // Ako validacija nije prošla, prekinemo izvršavanje
    if (!isValid) {
      return;
    }

    const formattedEvent = {
      ...selectedEvent,
      start: moment(selectedEvent.start).format("YYYY-MM-DD HH:mm:ss"),
      end: moment(selectedEvent.end).format("YYYY-MM-DD HH:mm:ss"),
      doktori: selectedEvent.doktori.filter(Boolean),
    };

    axios.post(`${apiUrl}/skripte/agenda.php?action=update`, formattedEvent).then((response) => {
      if (response.data.success) {
        fetchEvents(); // Ponovno dohvaćanje događaja
        toast.success("Događaj uspješno ažuriran!");
        setEditMode(false); // Onemogući uređivanje
        handleCloseSelectedEventModal(); // Zatvori modal
      } else {
        toast.error("Greška prilikom ažuriranja događaja.");
      }
    });
  };

  const handleCancelEdit = () => {
    setSelectedEvent({ ...originalEvent });
    setEditMode(false);
  };

  // Filtrirani eventi za odabrani datum
  const eventsForSelectedDate = events.filter((event) => {
    // Proveri da li je događaj u odabranoj sobi i na odabranom datumu
    const eventDate = new Date(event.start).toDateString();
    const selectedRoomId = selectedRoom;  // Pretpostavljam da je selectedRoom jednak resourceId

    return event.resourceId === selectedRoomId && eventDate === selectedDate.toDateString();
  });


  // Funkcija za dohvaćanje naziva proizvoda na temelju productId
  const getProductName = (productId) => {
    const product = products.find((product) => product.id === productId);
    return product ? product.naziv_artikla : 'Nepoznati proizvod';
  };

  return (
    <div>
      {/* Računalna verzija */}
      {!isMobile ? (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <div style={{ marginRight: '20px' }}>
              {/* Gumb za dodavanje događaja */}
              <Button variant="contained" startIcon={<CalendarMonthIcon />} color="primary" style={{ marginRight: '10px' }} onClick={handleOpenAddModal}>
                Dodaj novi termin
              </Button>
              <Button variant="contained" startIcon={<AddCircleIcon />} onClick={handleOpenPatientModal}>
                Dodaj novog pacijenta
              </Button>
            </div>

            {/* DatePicker za odabir datuma */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Odaberite datum"
                value={dayjs(selectedDate)}
                format="DD/MM/YYYY"
                onChange={(date) => setSelectedDate(date?.toDate())}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ backgroundColor: 'white', borderRadius: '4px', marginRight: '10px' }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>


          <Calendar
            localizer={localizer}
            events={events}
            defaultView={Views.DAY}
            views={[Views.DAY]}
            step={60}
            resources={resources}
            date={selectedDate}
            onNavigate={(newDate) => setSelectedDate(newDate)}
            idAccessor="id"
            resourceTitleAccessor="resourceTitle"
            style={{ flexGrow: 1 }}
            step={30}
            timeslots={2}
            min={new Date(1970, 1, 1, 10, 0)}  // Početak dana u 10:00
            max={new Date(1970, 1, 1, 20, 0)} // Kraj dana u 20:00
            components={{
              event: CustomEvent,
            }}
            formats={{
              timeGutterFormat: 'HH:mm',  // Prikaz vremena u 24-satnom formatu
            }}
            onSelectEvent={handleSelectEvent}
          />

          {/* Modal za dodavanje događaja */}
          <Modal open={openAddModal}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handleCloseAddModal();
              }
            }}>
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: 400
            }}>
              <h2>Novi termin</h2>
              {/* Forma za dodavanje novog događaja */}
              <Autocomplete
                options={patients}
                getOptionLabel={(option) => `${option.ime} ${option.prezime}`}
                value={patients.find(patient => patient.id === newEvent.pacijent_id) || null}  // Koristi null ako pacijent nije izabran
                onChange={(event, newValue) => {
                  setNewEvent({ ...newEvent, pacijent_id: newValue ? newValue.id : "" });  // Postavi praznu vrednost ako nije odabran pacijent
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pacijent"
                    margin="normal"
                    fullWidth
                  />
                )}
              />

              <TextField
                label="Vrijeme početka"
                type="datetime-local"
                name="start"
                value={moment(newEvent.start).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) => setNewEvent({ ...newEvent, start: e.target.value })}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Vrijeme završetka"
                type="datetime-local"
                name="end"
                value={moment(newEvent.end).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) => setNewEvent({ ...newEvent, end: e.target.value })}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                select
                label="Usluga"
                name="productId"
                value={newEvent.productId}
                onChange={(e) => setNewEvent({ ...newEvent, productId: e.target.value })}
                fullWidth
                margin="normal"
              >
                {products.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.naziv_artikla} {/* Koristimo naziv proizvoda */}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label="Doktor 1"
                name="doctor_1"
                value={newEvent.doctor_1}
                onChange={(e) => setNewEvent({ ...newEvent, doctor_1: e.target.value })}
                fullWidth
                margin="normal"
              >
                {doctors
                  .filter((doctor) => doctor.permission_id === 2)
                  .map((doctor) => (
                    <MenuItem key={doctor.id} value={doctor.id}>
                      {doctor.ime} {doctor.prezime}
                    </MenuItem>
                  ))}
              </TextField>

              {/* Polje za drugog doktora, disabled ako nije u vremenskom rasponu između 12 i 14 */}
              <TextField
                select
                label="Doktor 2"
                name="doctor_2"
                value={newEvent.doctor_2}
                onChange={(e) => setNewEvent({ ...newEvent, doctor_2: e.target.value })}
                fullWidth
                margin="normal"
                disabled={!isTimeInRange} // Disabled ako nije između 12:00 i 14:00
              >
                {doctors
                  .filter((doctor) => doctor.permission_id === 2)
                  .map((doctor) => (
                    <MenuItem key={doctor.id} value={doctor.id}>
                      {doctor.ime} {doctor.prezime}
                    </MenuItem>
                  ))}
              </TextField>

              <Button variant="contained" startIcon={<SaveIcon />} color="primary" style={{ marginRight: '10px' }} onClick={handleSubmitEvent}>
                Spremi događaj
              </Button>
              <Button variant="outlined" onClick={handleCloseAddModal}>
                Odustani
              </Button>
            </Box>
          </Modal>

          {/* Modal za prikaz i uređivanje odabranog događaja */}
          <Modal open={openSelectedEventModal}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handleCloseSelectedEventModal(); // Tvoj kod za zatvaranje modala
              }
            }}>
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: 400
            }}>
              {selectedEvent ? (
                <>
                  <h2>Detalji termina</h2>
                  {!isZaprimljen && (
                    <Button
                      variant="contained"
                      color="success"
                      style={{ marginBottom: '10px' }}
                      startIcon={<CheckCircleOutlineIcon />}
                      sx={{ marginRight: 2 }}
                      onClick={handleZaprimanjePacijenta}
                    >
                      Zaprimi pacijenta
                    </Button>
                  )}
                  <Autocomplete
                    options={patients}  // Lista pacijenata iz baze podataka
                    disabled={!editMode}
                    getOptionLabel={(option) => `${option.ime} ${option.prezime}`}  // Prikaz imena i prezimena pacijenta
                    value={patients.find(patient => patient.id === selectedEvent.pacijent_id) || null}  // Trenutni pacijent
                    onChange={(event, newValue) => {
                      setSelectedEvent({ ...selectedEvent, pacijent_id: newValue ? newValue.id : null });
                    }}  // Ažurira pacijent_id kada pacijent bude odabran
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pacijent"  // Naslov polja
                        margin="normal"
                        fullWidth
                        disabled={!editMode}  // Omogući ili onemogući promenu na osnovu editMode
                      />
                    )}
                  />
                  <TextField
                    label="Vrijeme početka"
                    name="start"
                    value={moment(selectedEvent.start).format("YYYY-MM-DDTHH:mm")}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    type="datetime-local"
                    disabled={!editMode}
                  />
                  <TextField
                    label="Vrijeme završetka"
                    name="end"
                    value={moment(selectedEvent.end).format("YYYY-MM-DDTHH:mm")}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    type="datetime-local"
                    disabled={!editMode}
                  />
                  <TextField
                    label="Usluga"
                    name="productId"
                    value={selectedEvent.productId}
                    onChange={handleInputChange}
                    select
                    fullWidth
                    margin="normal"
                    disabled={!editMode}
                  >
                    {products.map((product) => (
                      <MenuItem key={product.id} value={product.id}>
                        {product.naziv_artikla} {/* Koristimo naziv proizvoda */}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    label="Doktor 1"
                    name="doctor_1"
                    value={selectedEvent.doktori[0] || ""}  // Ako doctor_1 nije definiran, postavi praznu vrijednost
                    onChange={(e) => {
                      const updatedDoctors = [...selectedEvent.doktori];
                      updatedDoctors[0] = e.target.value;  // Postavi ID doktora
                      setSelectedEvent({ ...selectedEvent, doktori: updatedDoctors });
                    }}
                    fullWidth
                    margin="normal"
                    disabled={!editMode}
                  >
                    {doctors
                      .filter((doctor) => doctor.permission_id === 2)
                      .map((doctor) => (
                        <MenuItem key={doctor.id} value={doctor.id}>
                          {doctor.ime} {doctor.prezime}  {/* Prikazuje ime doktora, ali koristi ID kao vrijednost */}
                        </MenuItem>
                      ))}
                  </TextField>

                  <TextField
                    select
                    label="Doktor 2"
                    name="doctor_2"
                    value={selectedEvent.doktori[1] || ""}  // Ako doctor_2 nije definiran, postavi praznu vrijednost
                    onChange={(e) => {
                      const updatedDoctors = [...selectedEvent.doktori];
                      updatedDoctors[1] = e.target.value;  // Postavi ID doktora
                      setSelectedEvent({ ...selectedEvent, doktori: updatedDoctors });
                    }}
                    fullWidth
                    margin="normal"
                    disabled={!isTimeInRange || !editMode}  // Omogućeno samo ako je unutar 12:00-14:00
                  >
                    {doctors
                      .filter((doctor) => doctor.permission_id === 2)
                      .map((doctor) => (
                        <MenuItem key={doctor.id} value={doctor.id}>
                          {doctor.ime} {doctor.prezime}  {/* Prikazuje ime doktora, ali koristi ID kao vrijednost */}
                        </MenuItem>
                      ))}
                  </TextField>

                  {/* Akcijski gumbi */}
                  {!editMode ? (
                    <div>
                      <Button variant="contained" startIcon={<EditIcon />} onClick={handleEdit} sx={{ marginRight: 2 }}>
                        Uredi
                      </Button>
                      <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleOpenDeleteDialog} sx={{ marginRight: 2 }}>
                        Izbriši
                      </Button>
                      <Button variant="outlined" onClick={handleCloseSelectedEventModal}>
                        Zatvori
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={handleUpdate} sx={{ marginRight: 2 }}>
                        Ažuriraj
                      </Button>
                      <Button variant="outlined" color="primary" onClick={handleCancelEdit}>
                        Odustani
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <h2>Učitavanje...</h2>
              )}
            </Box>
          </Modal>

          <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
          >
            <Box p={3}>
              <h2>Potvrda brisanja</h2>
              <p>Jeste li sigurni da želite izbrisati ovaj događaj?</p>
              <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleDeleteConfirmed}>
                Izbriši
              </Button>
              <Button variant="contained" onClick={handleCloseDeleteDialog} sx={{ marginLeft: 2 }}>
                Odustani
              </Button>
            </Box>
          </Dialog>

          <Dialog open={openPatientModal}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handleClosePatientModal(); // Tvoj kod za zatvaranje modala
              }
            }}>
            <DialogTitle>Novi pacijent</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Ime"
                type="text"
                fullWidth
                variant="outlined"
                name="ime"
                value={patientData.ime}
                onChange={handlePatientInputChange}
              />
              <TextField
                margin="dense"
                label="Prezime"
                type="text"
                fullWidth
                variant="outlined"
                name="prezime"
                value={patientData.prezime}
                onChange={handlePatientInputChange}
              />
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                name="email"
                value={patientData.email}
                onChange={handlePatientInputChange}
              />

              <PhoneInput
                country={'hr'}   // Postavi Hrvatsku kao defaultni pozivni broj
                value={patientData.mobitel}
                onChange={handlePhoneChange}  // Funkcija za postavljanje broja
                style={{ marginTop: '10px' }}
                inputStyle={{ width: '100%', height: '50px' }}  // Stiliziranje inputa
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePatientModal}>Odustani</Button>
              <Button onClick={handleSubmitPatient} variant="contained">
                Dodaj pacijenta
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        /* Mobilna verzija */
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <h2>Termini</h2>

          {/* DatePicker za odabir datuma */}
          <div style={{ marginBottom: '10px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Odaberite datum"
                value={dayjs(selectedDate)}
                format="DD/MM/YYYY"
                onChange={(date) => setSelectedDate(date?.toDate())}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ backgroundColor: 'white', borderRadius: '4px', marginBottom: '10px' }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>


          {/* Dropdown za odabir sobe */}
          <FormControl fullWidth>
            <InputLabel id="room-select-label">Odaberite sobu:</InputLabel>
            <Select
              labelId="room-select-label"
              id="room-select"
              value={selectedRoom}
              onChange={(e) => setSelectedRoom(parseInt(e.target.value))}
              label="Odaberite sobu"
            >
              {resources.map((resource) => (
                <MenuItem key={resource.id} value={resource.id}>
                  {resource.resourceTitle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Prikaz podataka iz baze za odabranu sobu */}
          {selectedRoom && (
            <div style={{ flexGrow: 1, overflow: 'auto', marginBottom: '100px' }}>
              <h3>Termini za {resources.find((res) => res.id === selectedRoom)?.resourceTitle || selectedRoom} na datum {dayjs(selectedDate).format('DD/MM/YYYY')}</h3>
              <List>
                {eventsForSelectedDate.length > 0 ? (
                  eventsForSelectedDate.map((event) => (
                    <ListItem key={event.id}>
                      <ListItemText
                        primary={
                          <div style={{ color: 'rgb(95, 0, 0)' }}>{event.pacijent_id}</div>
                        }
                        secondary={
                          <>
                            <div className="event-time">
                              <AccessTimeIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} />
                              {formatTime(new Date(event.start))} - {formatTime(new Date(event.end))}
                            </div>
                            <div className="event-room">
                              <DescriptionIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} />
                              {event.device} | {getProductName(event.productId)}<br />
                            </div>
                            <div className="event-doctor">
                              <AccountCircleIcon sx={{ marginRight: '5px' }} />
                              {event.doctorIme} {event.doctorPrezime}
                            </div>
                          </>
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="Nema zakazanih termina za ovu sobu." />
                  </ListItem>
                )}
              </List>
            </div>
          )}

        </div>
      )}
    </div>
  );
};

export default MyCalendar;
