import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const SimpleComponent = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <h1>Ovo je testni ispis</h1>
      <p>Ovdje provjeravamo ispisnu funkcionalnost.</p>
    </div>
  );
});

const PrintTest = () => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <SimpleComponent ref={componentRef} />
      <button onClick={handlePrint}>Ispiši jednostavan test</button>
    </div>
  );
};

export default PrintTest;
