import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Zaposlenici = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [employees, setEmployees] = useState([]);
    const [roles, setRoles] = useState([]);
    const [editingEmployeeId, setEditingEmployeeId] = useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openAddModal, setOpenAddModal] = useState(false); // Kontrola modala za dodavanje zaposlenika
    const [newEmployee, setNewEmployee] = useState({
        username: '',
        firstName: '',
        lastName: '',
        oib: '',
        email: '',
        password: '',
        role: ''
    });

    const [editFormData, setEditFormData] = useState({
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        oib: '',
        role: ''
    });

    useEffect(() => {
        const fetchEmployeesAndRoles = async () => {
            try {
                const [employeesResponse, rolesResponse] = await Promise.all([
                    axios.get(`${apiUrl}/skripte/account.php?admin=true`),
                    axios.get(`${apiUrl}/skripte/account.php?roles=true`)
                ]);

                console.log('Employees Response:', employeesResponse.data);

                if (employeesResponse.data && Array.isArray(employeesResponse.data.users)) {
                    setEmployees(employeesResponse.data.users);
                } else {
                    setEmployees([]);
                }

                if (rolesResponse.data && Array.isArray(rolesResponse.data.roles)) {
                    setRoles(rolesResponse.data.roles);
                } else {
                    setRoles([]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setEmployees([]);
                setRoles([]);
            }
        };

        fetchEmployeesAndRoles();
    }, [apiUrl]);

    const handleEditClick = (employee) => {
        setEditingEmployeeId(employee.id);
        setEditFormData({
            username: employee.username,
            firstName: employee.firstName,
            lastName: employee.lastName,
            email: employee.email,
            oib: employee.oib,
            role: employee.role || ''
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSaveClick = async () => {
        try {
            const payload = {
                update: true,
                id: editingEmployeeId,
                username: editFormData.username,
                firstName: editFormData.firstName,
                lastName: editFormData.lastName,
                email: editFormData.email,
                oib: editFormData.oib,
                role: editFormData.role,
            };

            const response = await fetch(`${apiUrl}/skripte/account.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Problem pri ažuriranju podataka');
            }

            const responseData = await response.json();
            if (responseData.success) {
                // Ponovno dohvaćanje zaposlenika nakon uspješnog spremanja podataka
                const updatedResponse = await axios.get(`${apiUrl}/skripte/account.php?admin=true`);
                if (updatedResponse.data && Array.isArray(updatedResponse.data.users)) {
                    setEmployees(updatedResponse.data.users);
                }

                setEditingEmployeeId(null);
                toast.success('Podaci su uspješno ažurirani!');
            } else {
                toast.error('Ažuriranje nije uspjelo.');
                console.error('Ažuriranje nije uspjelo: ', responseData.message);
            }
        } catch (error) {
            console.error('Došlo je do greške: ', error);
            toast.error('Došlo je do greške.');
        }
    };

    const handleCancelClick = () => {
        setEditingEmployeeId(null);
    };

    const handleAddEmployeeChange = (e) => {
        const { name, value } = e.target;
        setNewEmployee((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Dodavanje novog zaposlenika
    const handleAddEmployee = async () => {
        try {
            // Priprema podataka za slanje na backend s action: add
            const response = await axios.post(`${apiUrl}/skripte/account.php`, {
                action: 'add', // Dodaj action: 'add' kako bi PHP prepoznao da se radi o dodavanju korisnika
                username: newEmployee.username,
                firstName: newEmployee.firstName,
                lastName: newEmployee.lastName,
                oib: newEmployee.oib,
                email: newEmployee.email,
                password: newEmployee.password,
                role: newEmployee.role
            });

            // Ako je korisnik uspješno dodan
            if (response.data.success) {
                // Osvježi listu zaposlenika
                const updatedEmployees = await axios.get(`${apiUrl}/skripte/account.php?admin=true`);
                setEmployees(updatedEmployees.data.users);
                toast.success('Zaposlenik uspješno dodan!');

                // Zatvori modal i resetiraj formu
                setOpenAddModal(false);
                setNewEmployee({
                    username: '',
                    firstName: '',
                    lastName: '',
                    oib: '',
                    email: '',
                    password: '',
                    role: ''
                });
            } else {
                // Ako dodavanje nije uspjelo, prikaži poruku o grešci
                toast.error(response.data.message || 'Greška prilikom dodavanja zaposlenika.');
            }
        } catch (error) {
            console.error('Greška prilikom dodavanja zaposlenika:', error);
            toast.error('Došlo je do greške prilikom dodavanja zaposlenika.');
        }
    };

    // Funkcija za otvaranje modala za potvrdu brisanja
    const handleConfirmDeleteOpen = (employee) => {
        setSelectedUser(employee);
        setOpenConfirmDelete(true);
    };

    // Funkcija za zatvaranje modala bez brisanja
    const handleConfirmDeleteClose = () => {
        setOpenConfirmDelete(false);
        setSelectedUser(null);
    };

    // Funkcija za brisanje korisnika
    const handleDeleteUser = async () => {
        try {
            const response = await axios.post(`${apiUrl}/skripte/account.php`, {
                action: 'delete',
                id: selectedUser.id // Prosljeđujemo ID korisnika kojeg želimo obrisati
            });

            if (response.data.success) {
                // Ponovno dohvatimo zaposlenike nakon uspješnog brisanja
                const updatedEmployees = await axios.get(`${apiUrl}/skripte/account.php?admin=true`);
                setEmployees(updatedEmployees.data.users);
                toast.success('Zaposlenik uspješno obrisan!');
            } else {
                toast.error(response.data.message || 'Greška prilikom brisanja zaposlenika.');
            }
        } catch (error) {
            toast.error('Došlo je do greške prilikom brisanja zaposlenika.');
        } finally {
            handleConfirmDeleteClose(); // Zatvaramo modal nakon brisanja
        }
    };

    return (
        <div>
            <h2>Zaposlenici</h2>
            <Button variant="contained" color="primary" startIcon={<AddCircleIcon />} style={{ marginBottom: '10px' }} onClick={() => setOpenAddModal(true)}>
                Dodaj zaposlenika
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>Ime</TableCell>
                            <TableCell>Prezime</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>OIB</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Akcija</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees.map((employee) => (
                            <TableRow key={employee.id}>
                                <TableCell>
                                    {editingEmployeeId === employee.id ? (
                                        <TextField
                                            name="username"
                                            label="Username"
                                            value={editFormData.username}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    ) : (
                                        employee.username
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingEmployeeId === employee.id ? (
                                        <TextField
                                            name="firstName"
                                            label="Ime"
                                            value={editFormData.firstName}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    ) : (
                                        employee.firstName
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingEmployeeId === employee.id ? (
                                        <TextField
                                            name="lastName"
                                            label="Prezime"
                                            value={editFormData.lastName}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    ) : (
                                        employee.lastName
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingEmployeeId === employee.id ? (
                                        <TextField
                                            name="email"
                                            label="Email"
                                            value={editFormData.email}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            type="email"
                                        />
                                    ) : (
                                        employee.email
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingEmployeeId === employee.id ? (
                                        <TextField
                                            name="oib"
                                            label="OIB"
                                            value={editFormData.oib}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    ) : (
                                        employee.oib
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingEmployeeId === employee.id ? (
                                        <Select
                                            name="role"
                                            value={editFormData.role || 'N/A'}
                                            onChange={handleInputChange}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                        >
                                            {roles.map((role) => (
                                                <MenuItem key={role.id} value={role.role}>
                                                    {role.role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        employee.role
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingEmployeeId === employee.id ? (
                                        <>
                                            <Button
                                                onClick={handleSaveClick}
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                            >
                                                Spremi
                                            </Button>
                                            <Button
                                                onClick={handleCancelClick}
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                style={{ marginLeft: '10px' }}
                                            >
                                                Odustani
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                onClick={() => handleEditClick(employee)}
                                                variant="outlined"
                                                color="primary"
                                                startIcon={<EditIcon />}
                                                size="small"
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="error" 
                                                startIcon={<DeleteIcon />} 
                                                size="small"
                                                onClick={() => handleConfirmDeleteOpen(employee)} 
                                                style={{ marginLeft: '10px' }}>
                                                Obriši
                                            </Button>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal za dodavanje zaposlenika */}
            <Dialog open={openAddModal} onClose={() => setOpenAddModal(false)}>
                <DialogTitle>Dodaj novog zaposlenika</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Username"
                        name="username"
                        value={newEmployee.username}
                        onChange={handleAddEmployeeChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Ime"
                        name="firstName"
                        value={newEmployee.firstName}
                        onChange={handleAddEmployeeChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Prezime"
                        name="lastName"
                        value={newEmployee.lastName}
                        onChange={handleAddEmployeeChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="OIB"
                        name="oib"
                        value={newEmployee.oib}
                        onChange={handleAddEmployeeChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={newEmployee.email}
                        onChange={handleAddEmployeeChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Lozinka"
                        type="password"
                        name="password"
                        value={newEmployee.password}
                        onChange={handleAddEmployeeChange}
                        fullWidth
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Role</InputLabel>
                        <Select
                            name="role"
                            value={newEmployee.role}
                            onChange={handleAddEmployeeChange}
                            fullWidth
                        >
                            {roles.map((role) => (
                                <MenuItem key={role.id} value={role.role}>
                                    {role.role}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddEmployee} color="primary" variant="contained">
                        Dodaj
                    </Button>
                    <Button onClick={() => setOpenAddModal(false)} color="primary" variant="outlined">
                        Odustani
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConfirmDelete}
                onClose={handleConfirmDeleteClose}
            >
                <DialogTitle>Potvrda brisanja</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Jeste li sigurni da želite obrisati zaposlenika {selectedUser?.firstName} {selectedUser?.lastName}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDeleteClose} color="primary">
                        Odustani
                    </Button>
                    <Button onClick={handleDeleteUser} color="error">
                        Obriši
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Zaposlenici;
