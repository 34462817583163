import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
        Copyright &copy; {currentYear}. | <span>Maratea</span>
    </div>
  );
}

export default Footer;
