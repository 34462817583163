import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import predlozakPDF from './template.pdf';
import robotoFont from './Poppins-Light.ttf';
import robotoBoldFont from './Poppins-Bold.ttf';

const IspisRacuna = async (racun, operater, nacinPlacanja) => {
  const existingPdfBytes = await fetch(predlozakPDF).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  pdfDoc.registerFontkit(fontkit);
  const fontBytes = await fetch(robotoFont).then(res => res.arrayBuffer());
  const robotoFontEmbedded = await pdfDoc.embedFont(fontBytes);
  // Učitajte i registrirajte bold font
  const boldFontBytes = await fetch(robotoBoldFont).then(res => res.arrayBuffer());
  const robotoBoldFontEmbedded = await pdfDoc.embedFont(boldFontBytes);
  const { width, height } = pdfDoc.getPages()[0].getSize();
  const fontSize = 8;
  const yOffset = 100;
  const maxItemsPerPage = 7;
  let yPos = height - 120 - yOffset;
  let currentPage = pdfDoc.getPages()[0];

  // Funkcija za wrap teksta na temelju širine linije
  const wrapText = (text, maxWidth) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = robotoFontEmbedded.widthOfTextAtSize(`${currentLine} ${word}`, fontSize);
      if (width < maxWidth) {
        currentLine += ` ${word}`;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  const alignRight = (text, xPosition) => {
    const textWidth = robotoFontEmbedded.widthOfTextAtSize(text.toString(), fontSize); // Dodajte `.toString()` ovdje
    return xPosition - textWidth;
  };

  const rightAlignX = width - 50;

  // Dodaj osnovne informacije o kupcu i datumu računa
  currentPage.drawText('Kupac:', { x: 20, y: height - 50 - yOffset, size: fontSize, font: robotoFontEmbedded, color: rgb(0, 0, 0) });
  currentPage.drawText(`Datum računa: ${new Date().toLocaleDateString()}`, { x: width - 150, y: height - 50 - yOffset, size: fontSize, font: robotoFontEmbedded, color: rgb(0, 0, 0) });
  currentPage.drawText(`Račun br.`, { x: width - 150, y: height - 65 - yOffset, size: fontSize, font: robotoFontEmbedded, color: rgb(0, 0, 0) });

  // Zaglavlje tablice
  currentPage.drawText('Proizvod', { x: 20, y: height - 100 - yOffset, size: fontSize, font: robotoBoldFontEmbedded, color: rgb(0, 0, 0) });
  currentPage.drawText('Cijena', { x: 150, y: height - 100 - yOffset, size: fontSize, font: robotoBoldFontEmbedded, color: rgb(0, 0, 0) });
  currentPage.drawText('Količina', { x: 200, y: height - 100 - yOffset, size: fontSize, font: robotoBoldFontEmbedded, color: rgb(0, 0, 0) });
  currentPage.drawText('Popust (%)', { x: 250, y: height - 100 - yOffset, size: fontSize, font: robotoBoldFontEmbedded, color: rgb(0, 0, 0) });
  currentPage.drawText('Ukupna cijena', { x: 300, y: height - 100 - yOffset, size: fontSize, font: robotoBoldFontEmbedded, color: rgb(0, 0, 0) });

  racun.forEach((item, index) => {
    if (index > 0 && index % maxItemsPerPage === 0) {
      // Dodajte novu stranicu
      currentPage = pdfDoc.addPage([width, height]);

      // Postavite početnu y poziciju za nove stranice
      yPos = height - 50; // Ovo je za nove stranice bez zaglavlja (npr. -50)

      // Opcionalno: možete dodati tablicu bez zaglavlja
      currentPage.drawText('Proizvod', { x: 20, y: yPos, size: fontSize, font: robotoBoldFontEmbedded, color: rgb(0, 0, 0) });
      currentPage.drawText('Cijena', { x: 150, y: yPos, size: fontSize, font: robotoBoldFontEmbedded, color: rgb(0, 0, 0) });
      currentPage.drawText('Količina', { x: 200, y: yPos, size: fontSize, font: robotoBoldFontEmbedded, color: rgb(0, 0, 0) });
      currentPage.drawText('Popust (%)', { x: 250, y: yPos, size: fontSize, font: robotoBoldFontEmbedded, color: rgb(0, 0, 0) });
      currentPage.drawText('Ukupna cijena', { x: 300, y: yPos, size: fontSize, font: robotoBoldFontEmbedded, color: rgb(0, 0, 0) });

      yPos -= 20; // Pomakni yPos za stavke na novoj stranici
    } else if (index === 0) {
      // Prva stranica, gdje je yPos različit zbog zaglavlja
      yPos = height - 120 - yOffset;
    }

    // Nastavite s ispisom stavki, koristeći novi yPos
    const nameLines = wrapText(item.naziv_artikla || 'N/A', 100);
    nameLines.forEach((line, i) => {
      currentPage.drawText(line, { x: 20, y: yPos - (i * 10), size: fontSize, font: robotoFontEmbedded, color: rgb(0, 0, 0) });
    });

    const linesHeight = (nameLines.length - 1) * 10;
    currentPage.drawText(item.vpc ? `${Number(item.vpc).toFixed(2)} €` : '0.00', { x: alignRight(`${Number(item.vpc).toFixed(2)} €`, 180), y: yPos - linesHeight, size: fontSize, font: robotoFontEmbedded, color: rgb(0, 0, 0) });
    currentPage.drawText(item.kolicina ? item.kolicina.toString() : '0', { x: alignRight(item.kolicina.toString(), 230), y: yPos - linesHeight, size: fontSize, font: robotoFontEmbedded, color: rgb(0, 0, 0) });
    currentPage.drawText(item.popust ? item.popust.toString() : '0', { x: alignRight(item.popust.toString(), 280), y: yPos - linesHeight, size: fontSize, font: robotoFontEmbedded, color: rgb(0, 0, 0) });
    currentPage.drawText(item.cijena ? `${Number(item.cijena).toFixed(2).toString()} €` : '0.00', { x: alignRight(`${Number(item.cijena).toFixed(2).toString()} €`, 360), y: yPos - linesHeight, size: fontSize, font: robotoFontEmbedded, color: rgb(0, 0, 0) });

    yPos -= 20 + linesHeight;
  });


  currentPage.drawLine({
    start: { x: 20, y: yPos - 5 },
    end: { x: width - 20, y: yPos - 5 },
    thickness: 1,
    color: rgb(0, 0, 0)
  });

  yPos -= 30;

  // Pretvorite u string pomoću `toString()` nakon `toFixed(2)`
  const ukupnaCijenaBezPDV = Number(racun.reduce((total, item) => total + Number(item.cijena || 0), 0)).toFixed(2);
  const iznosPDV = (parseFloat(ukupnaCijenaBezPDV) * 0.25).toFixed(2).toString();
  const ukupnoSaPDV = (parseFloat(ukupnaCijenaBezPDV) + parseFloat(iznosPDV)).toFixed(2);

  // Tek u pozivima drawText, koriste toString za sve što može da bude broj:
  currentPage.drawText(`Ukupno bez PDV-a: ${ukupnaCijenaBezPDV} €`, {
    x: alignRight(`Ukupno bez PDV-a: ${ukupnaCijenaBezPDV} €`, rightAlignX),
    y: yPos,
    size: fontSize,
    font: robotoFontEmbedded,
    color: rgb(0, 0, 0)
  });

  currentPage.drawText(`Iznos PDV-a (25%): ${iznosPDV} €`, {
    x: alignRight(`Iznos PDV-a (25%): ${iznosPDV} €`, rightAlignX),
    y: yPos - 15,
    size: fontSize,
    font: robotoFontEmbedded,
    color: rgb(0, 0, 0)
  });

  currentPage.drawText(`Ukupno s PDV-om: ${ukupnoSaPDV} €`, {
    x: alignRight(`Ukupno s PDV-om: ${ukupnoSaPDV} €`, rightAlignX),
    y: yPos - 30,
    size: fontSize,
    font: robotoBoldFontEmbedded,
    color: rgb(0, 0, 0)
  });

  // Nakon dijela gdje ispisujete cijene s PDV-om, dodajte:

  yPos -= 50; // Podesite `yPos` za dodatni razmak od zadnjeg teksta

  currentPage.drawText(`Račun izdao: ${operater}`, {
    x: 20, // Početak linije (podesite po želji)
    y: yPos,
    size: fontSize,
    font: robotoFontEmbedded,
    color: rgb(0, 0, 0)
  });

  // Dodajte Način Plaćanja ispod Operatera
  yPos -= 15; // Dodatni razmak između operatera i načina plaćanja

  currentPage.drawText(`Način plaćanja: ${nacinPlacanja}`, {
    x: 20,
    y: yPos,
    size: fontSize,
    font: robotoFontEmbedded,
    color: rgb(0, 0, 0)
  });

  yPos -= 15; // Dodatni razmak između operatera i načina plaćanja

  currentPage.drawText(`JIR:`, {
    x: 20,
    y: yPos,
    size: fontSize,
    font: robotoFontEmbedded,
    color: rgb(0, 0, 0)
  });

  yPos -= 15; // Dodatni razmak između operatera i načina plaćanja

  currentPage.drawText(`ZKI:`, {
    x: 20,
    y: yPos,
    size: fontSize,
    font: robotoFontEmbedded,
    color: rgb(0, 0, 0)
  });


  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const blobURL = URL.createObjectURL(blob);
  const printWindow = window.open(blobURL);

  if (printWindow) {
    printWindow.onload = function () {
      printWindow.focus();
      printWindow.print();
    };
  }
};

export default IspisRacuna;
