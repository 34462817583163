import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Button, Modal, Box, Typography, TextField, Select, MenuItem, InputLabel, FormControl, Grid,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { toast } from 'react-toastify';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Add } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    maxHeight: '80vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const PaketProizvodi = () => {
    const [data, setData] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedPaket, setSelectedPaket] = useState(null);
    const [open, setOpen] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [newPaket, setNewPaket] = useState({ naziv: '', cijena: '', proizvodi: [{ proizvod_id: '', kolicina: '' }] });
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [paketToDelete, setPaketToDelete] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL;

    // Funkcija za dohvaćanje paketa i proizvoda iz API-ja
    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}/skripte/paketi.php`);
            const result = await response.json();

            // Grupiramo pakete po ID-u i dodajemo proizvode u array unutar svakog paketa
            const groupedData = result.reduce((acc, curr) => {
                const paketId = curr.paket_id;
                if (!acc[paketId]) {
                    acc[paketId] = {
                        paket_id: paketId,
                        paket_naziv: curr.paket_naziv,
                        cijena_bez_popusta: curr.cijena_bez_popusta,
                        popust: curr.popust,
                        cijena_sa_popustom: curr.cijena_sa_popustom,
                        proizvodi: []
                    };
                }
                acc[paketId].proizvodi.push({
                    naziv_artikla: curr.naziv_artikla,
                    kolicina: curr.kolicina
                });
                return acc;
            }, {});
            setData(Object.values(groupedData));
        } catch (error) {
            console.error('Greška prilikom dohvaćanja podataka:', error);
        }
    };

    // Funkcija za dohvaćanje proizvoda
    const fetchProducts = async () => {
        try {
            const response = await fetch(`${apiUrl}/skripte/proizvodi.php`);
            const result = await response.json();

            console.log('Proizvodi rezultat:', result); // Proveri strukturu odgovora

            // Proveri strukturu i postavi products ispravno
            setProducts(result.products || result || []); // Koristi niz iz objekta ili direktno rezultat
        } catch (error) {
            console.error('Greška prilikom dohvaćanja proizvoda:', error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchProducts();  // Dohvaćanje proizvoda prilikom mountanja komponente
    }, []);

    const handleOpen = (paket) => {
        setSelectedPaket(paket);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedPaket(null);
    };

    const handleAddModalOpen = () => {
        setOpenAddModal(true);
        setNewPaket({ naziv: '', cijena: '', proizvodi: [{ proizvod_id: '', kolicina: '' }] });
    };

    const handleAddModalClose = () => {
        setOpenAddModal(false);
        setNewPaket({ naziv: '', cijena: '', proizvodi: [] });
    };

    const handleAddProductField = () => {
        setNewPaket({
            ...newPaket,
            proizvodi: [...newPaket.proizvodi, { proizvod_id: '', kolicina: '' }]
        });
    };

    const handleProductChange = (index, field, value) => {
        const updatedProizvodi = [...newPaket.proizvodi];
        updatedProizvodi[index][field] = value;
        setNewPaket({ ...newPaket, proizvodi: updatedProizvodi });
    };

    // Funkcija za dodavanje novog paketa
    const handleAddPaket = async () => {
        try {
            const response = await fetch(`${apiUrl}/skripte/paketi.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    naziv: newPaket.naziv,
                    proizvodi: newPaket.proizvodi
                }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message);
                toast.success('Paket uspješno dodan!');
                handleAddModalClose();
                fetchData(); // Osvježi popis paketa
            } else {
                console.error('Greška prilikom dodavanja paketa');
                toast.error('Greška prilikom dodavanja paketa!');
            }
        } catch (error) {
            console.error('Greška u komunikaciji s API-jem', error);
        }
    };

    // Otvori dialog za brisanje paketa
    const handleDeleteClick = (paket) => {
        setPaketToDelete(paket);
        setOpenDeleteDialog(true);
    };

    // Zatvori dialog
    const handleDeleteClose = () => {
        setOpenDeleteDialog(false);
        setPaketToDelete(null);
    };

    // Potvrdi brisanje paketa
    const handleDeleteConfirm = async () => {
        if (!paketToDelete) return;

        try {
            const response = await fetch(`${apiUrl}/skripte/paketi.php`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: paketToDelete.paket_id }),
            });

            if (response.ok) {
                toast.success('Paket uspješno izbrisan!');
                fetchData(); // Osvježi podatke
                handleDeleteClose(); // Zatvori dialog
            } else {
                toast.error('Greška prilikom brisanja paketa');
                console.error('Greška prilikom brisanja paketa');
            }
        } catch (error) {
            console.error('Greška u komunikaciji s API-jem', error);
        }
    };

    // React effect za dohvaćanje podataka
    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            {/* Gumb za dodavanje novog paketa */}
            <Button variant="contained" color="primary" startIcon={<AddCircleIcon />} onClick={handleAddModalOpen}>
                Dodaj novi paket
            </Button>

            {/* Tablica za prikaz paketa */}
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Paket</strong></TableCell>
                            <TableCell align="right"><strong>Osnovna cijena</strong></TableCell>
                            <TableCell align="right"><strong>Popust</strong></TableCell>
                            <TableCell align="right"><strong>Ukupna cijena</strong></TableCell>
                            <TableCell align="right"><strong>Action</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.paket_naziv}
                                </TableCell>
                                <TableCell align="right">{row.cijena_bez_popusta} €</TableCell>
                                <TableCell align="right">{(row.popust * 100).toFixed(2)} %</TableCell>
                                <TableCell align="right">{row.cijena_sa_popustom} €</TableCell>
                                <TableCell align="right">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{ marginRight: '10px' }}
                                        onClick={() => handleOpen(row)}
                                    >
                                        Detalji
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => {
                                            console.log('Row data:', row); // Provjeri cijeli redak podataka
                                            handleDeleteClick({
                                                paket_id: row.paket_id,  // Eksplicitno šaljemo paket_id
                                                paket_naziv: row.paket_naziv,  // Šaljemo i naziv za dijalog
                                            });
                                        }}
                                    >
                                        Izbriši
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal za prikaz proizvoda */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    {selectedPaket && (
                        <>
                            <Typography id="modal-title" variant="h6" component="h2">
                                Proizvodi za paket: {selectedPaket.paket_naziv}
                            </Typography>
                            <Typography id="modal-description" sx={{ mt: 2 }}>
                                <ul>
                                    {selectedPaket.proizvodi.map((proizvod, index) => (
                                        <li key={index}>
                                            {proizvod.naziv_artikla} - Količina: {proizvod.kolicina}
                                        </li>
                                    ))}
                                </ul>
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClose}
                                >
                                    Zatvori
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Modal>

            {/* Modal za dodavanje novog paketa */}
            <Modal
                open={openAddModal}
                onClose={handleAddModalClose}
                aria-labelledby="add-modal-title"
                aria-describedby="add-modal-description"
            >
                <Box sx={style}>
                    <Typography id="add-modal-title" variant="h6" component="h2">
                        Dodaj novi paket
                    </Typography>

                    <TextField
                        fullWidth
                        margin="normal"
                        label="Naziv paketa"
                        value={newPaket.naziv}
                        onChange={(e) => setNewPaket({ ...newPaket, naziv: e.target.value })}
                    />

                    {/* Dinamičko dodavanje proizvoda, količine i gumba u istom redu */}
                    {newPaket.proizvodi.map((proizvod, index) => (
                        <Grid container spacing={2} alignItems="center" key={index}>
                            {/* Lista proizvoda */}
                            <Grid item xs={8}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id={`select-product-${index}`}>Proizvod</InputLabel>
                                    <Select
                                        labelId={`select-product-${index}`}
                                        value={proizvod.proizvod_id}
                                        onChange={(e) => handleProductChange(index, 'proizvod_id', e.target.value)}
                                    >
                                        {(Array.isArray(products) ? products : []).filter(product =>
                                            !newPaket.proizvodi.some(selectedProizvod => selectedProizvod.proizvod_id === product.id) || proizvod.proizvod_id === product.id
                                        ).map(product => (
                                            <MenuItem key={product.id} value={product.id}>
                                                {product.naziv_artikla}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Količina */}
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Količina"
                                    type="number"
                                    value={proizvod.kolicina}
                                    onChange={(e) => handleProductChange(index, 'kolicina', e.target.value)}
                                />
                            </Grid>

                            {/* Gumb "Dodaj" prikazuje se samo za zadnji proizvod */}
                            {index === newPaket.proizvodi.length - 1 && (
                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        style={{ marginTop: '16px' }}
                                        onClick={handleAddProductField}
                                    >
                                        Dodaj
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    ))}

                    <Box sx={{ mt: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '10px' }}
                            onClick={handleAddPaket}
                        >
                            Spremi
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAddModalClose}
                        >
                            Odustani
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Dialog za potvrdu brisanja */}
            <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteClose}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Potvrda brisanja</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Jeste li sigurni da želite izbrisati paket {paketToDelete?.paket_naziv}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Odustani
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="error" autoFocus>
                        Izbriši
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PaketProizvodi;
