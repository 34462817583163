import React, { useState } from 'react';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  //Prijava
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${apiUrl}/skripte/account.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    const data = await response.json();
    console.log(data);
    if (data.success) {
      login(data.user);
      navigate('/');
    } else {
      alert('Neuspješna prijava!');
    }
  };

  //Forgot password
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    // Slanje zahtjeva za zaboravljenu lozinku
    const response = await fetch(`${apiUrl}/skripte/password_reset.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `email=${forgotEmail}`
    });

    const data = await response.json();
    if (data.success) {
      alert('If your email is in our database, you will receive a password reset link.');
    } else {
      alert('There was a problem processing your request. Please try again.');
    }
    setShowForgotPassword(false);
  };

  return (
    <main>
      <div class="login">
        <div className="logo-big">
          <img src={require('../img/logo.png')} alt="Logo" />
        </div>
        <div class="login-form">
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="primary-button" type="submit">Login</button>
          </form>

          {showForgotPassword && (
            <form onSubmit={handleForgotPassword}>
              <TextField
                fullWidth
                margin="normal"
                label="Enter your email"
                type="email"
                value={forgotEmail}
                onChange={(e) => setForgotEmail(e.target.value)}
              />
              <button className="action-button-2" type="submit">Send Reset Link</button>
            </form>
          )}

          <div class="login-menu">
            {!showForgotPassword && (
              <p><button className="action-button-2" onClick={() => setShowForgotPassword(true)}>Forgot password?</button></p>
            )}
          </div>

        </div>
      </div>
    </main>
  );
};

export default Login;
