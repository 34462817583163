import React, { useState } from 'react';
import { useAuth } from '../components/AuthContext';
import { ToastContainer } from 'react-toastify';
import Navbar from '../components/navbar';
import Zaposlenici from '../components/Zaposlenici';
import Statistika from '../components/Statistika';
import Paketi from '../components/Paketi';
import Pacijenti from '../components/Pacijenti';
import Proizvodi from '../components/Proizvodi';

const AdminScreen = () => {
    const { user } = useAuth();
    const [activeComponent, setActiveComponent] = useState('Zaposlenici');

    // Funkcija za renderovanje odgovarajuće komponente
    const renderComponent = () => {
        switch (activeComponent) {
            case 'Zaposlenici':
                return <Zaposlenici />;
            case 'Statistika':
                return <Statistika />;
            case 'Pacijenti':
                return <Pacijenti />;
            case 'Proizvodi':
                return <Proizvodi />;
            case 'Paketi':
                return <Paketi />;
            default:
                return <Zaposlenici />;
        }
    };

    return (
        <div>
            {user && <Navbar />}
            <main className="community-main">
                <div className="content">
                    <nav className="admin-sidebar">

                        <ul>
                            <h3>Općenito</h3>
                            <li>
                                <button onClick={() => setActiveComponent('Zaposlenici')}>
                                    Zaposlenici
                                </button>
                            </li>  
                            <li>
                                <button onClick={() => setActiveComponent('Pacijenti')}>
                                    Baza pacijenata
                                </button>
                            </li>

                            <h3>Proizvodi</h3>

                            <li>
                                <button onClick={() => setActiveComponent('Proizvodi')}>
                                    Proizvodi
                                </button>
                            </li>
                            <li>
                                <button onClick={() => setActiveComponent('Paketi')}>
                                    Paketi
                                </button>
                            </li>

                            <h3>Statistika</h3>
                            
                            <li>
                                <button onClick={() => setActiveComponent('Statistika')}>
                                    Promet (grafovi)
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="content">
                    {renderComponent()}
                </div>
            </main>
            <ToastContainer />
        </div>
    );
};

export default AdminScreen;
