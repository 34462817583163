import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, TextField, MenuItem, TableSortLabel, Box, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Stilovi za modal
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    padding: '20px',
    boxShadow: 24,
    borderRadius: '8px',
    outline: 'none',
};

const Proizvodi = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [products, setProducts] = useState([]); // Stanje za proizvode iz baze
    const [tipoviArtikla, setTipoviArtikla] = useState([]);
    const [devices, setDevices] = useState([]);
    const [orderDirection, setOrderDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('naziv_artikla');
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false); // Modal za dodavanje/uređivanje proizvoda
    const [editMode, setEditMode] = useState(false); // Modal u načinu uređivanja
    const [currentProduct, setCurrentProduct] = useState(null); // Trenutno uređeni proizvod
    const [newProduct, setNewProduct] = useState({ naziv: '', mpc: '', tip_artikla: 'Proizvod' });

    // Dohvaćanje proizvoda iz baze podataka prilikom učitavanja stranice
    useEffect(() => {
        fetchProducts();
        // eslint-disable-next-line 
    }, []);

    const fetchProducts = () => {
        axios.get(`${apiUrl}/skripte/proizvodi.php`)
            .then((response) => {
                console.log('Dohvaćeni podaci:', response.data);
                setProducts(response.data.products || []);
                setTipoviArtikla(response.data.tipovi || []);
                setDevices(response.data.devices || []); // Dohvaćamo i uređaje
            })
            .catch((error) => {
                console.error('Greška prilikom dohvaćanja proizvoda:', error);
            });
    };

    // Otvaranje modalnog prozora za dodavanje proizvoda
    const handleOpen = () => {
        setEditMode(false);
        setNewProduct({ naziv: '', mpc: '', tip_artikla_id: '', device_id: '' });
        setOpen(true);
    };

    // Otvaranje modalnog prozora za uređivanje proizvoda
    const handleEditOpen = (product) => {
        setEditMode(true);
        setCurrentProduct(product);
        // Postavi vrednosti u skladu sa odabranim proizvodom
        setNewProduct({
            naziv: product.naziv_artikla,
            mpc: product.mpc,
            tip_artikla_id: product.tip_artikla_id || '',
            device_id: product.device_id || ''
        });
        setOpen(true);
    };

    // Zatvaranje modalnog prozora
    const handleClose = () => setOpen(false);

    // Filtriranje proizvoda na temelju pretrage
    const filteredProducts = products.filter((product) =>
        product.naziv_artikla.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Ažuriraj sortiranje proizvoda prema trenutnim kriterijima
    const sortedProducts = filteredProducts.sort((a, b) => {
        const valueA = a[orderBy];
        const valueB = b[orderBy];

        if (orderDirection === 'asc') {
            return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
            return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
    });

    // Dodavanje novog proizvoda
    const handleAddProduct = () => {
        if (newProduct.naziv && newProduct.mpc) {
            const formData = new FormData();
            formData.append('action', 'dodaj');
            formData.append('naziv', newProduct.naziv);
            formData.append('mpc', newProduct.mpc);
            formData.append('tip_artikla_id', newProduct.tip_artikla_id);
            formData.append('device_id', newProduct.device_id); // Dodajemo device_id

            axios.post(`${apiUrl}/skripte/proizvodi.php`, formData)
                .then((response) => {
                    toast.success('Podaci su uspješno dodani');
                    fetchProducts();
                    handleClose();
                })
                .catch((error) => {
                    toast.error('Greška prilikom dodavanja proizvoda');
                    console.error('Greška prilikom dodavanja proizvoda:', error);
                });
        }
    };

    const handleEditProduct = () => {
        const formData = new FormData();
        formData.append('action', 'uredi');
        formData.append('id', currentProduct.id);
        formData.append('naziv', newProduct.naziv);
        formData.append('mpc', newProduct.mpc);
        formData.append('tip_artikla_id', newProduct.tip_artikla_id);
        formData.append('device_id', newProduct.device_id); // Dodajemo device_id

        axios.post(`${apiUrl}/skripte/proizvodi.php`, formData)
            .then((response) => {
                toast.success('Podaci su uspješno ažurirani!');
                fetchProducts();
                handleClose();
            })
            .catch((error) => {
                toast.error('Greška prilikom ažuriranja proizvoda');
                console.error('Greška prilikom ažuriranja proizvoda:', error);
            });
    };


    // Brisanje proizvoda
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);

    // Otvaranje Dialoga za potvrdu brisanja
    const handleConfirmDeleteOpen = (product) => {
        setProductToDelete(product);
        setOpenConfirmDelete(true);
    };

    // Zatvaranje Dialoga za potvrdu brisanja
    const handleConfirmDeleteClose = () => {
        setProductToDelete(null);
        setOpenConfirmDelete(false);
    };

    const handleDeleteProduct = () => {
        if (!productToDelete || !productToDelete.id) {
            console.error('Nema ID-a za brisanje'); // Provjera da li ID postoji
            return;
        }

        const formData = new FormData();  // Kreiramo novi FormData objekt
        formData.append('action', 'obrisi');  // Dodajemo action
        formData.append('id', productToDelete.id);  // Dodajemo ID proizvoda

        // Ispisujemo FormData podatke za provjeru
        for (var pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        axios.post(`${apiUrl}/skripte/proizvodi.php`, formData)
            .then((response) => {
                toast.success('Podaci su uspješno izbrisani!');
                fetchProducts();  // Ponovno dohvaćanje proizvoda
                handleConfirmDeleteClose();  // Zatvori Dialog za brisanje
            })
            .catch((error) => {
                toast.error('Greška prilikom brisanja proizvoda');
                console.error('Greška prilikom brisanja proizvoda:', error);
            });
    };

    return (
        <div>
            <h1>Proizvodi</h1>

            <Button variant="contained" color="primary" startIcon={<AddCircleIcon />} onClick={handleOpen}>
                Dodaj Proizvod
            </Button>

            <TextField
                label="Pretraga proizvoda"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ margin: '20px 0' }}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'naziv_artikla'}
                                    direction={orderBy === 'naziv_artikla' ? orderDirection : 'asc'}
                                    onClick={() => handleRequestSort('naziv_artikla')}
                                >
                                    Naziv
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === 'mpc'}
                                    direction={orderBy === 'mpc' ? orderDirection : 'asc'}
                                    onClick={() => handleRequestSort('mpc')}
                                >
                                    MPC
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === 'vpc'}
                                    direction={orderBy === 'vpc' ? orderDirection : 'asc'}
                                    onClick={() => handleRequestSort('vpc')}
                                >
                                    VPC
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === 'tip_artikla'}
                                    direction={orderBy === 'tip_artikla' ? orderDirection : 'asc'}
                                    onClick={() => handleRequestSort('tip_artikla')}
                                >
                                    Tip Artikla
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">Uređaj</TableCell> {/* Nova kolona za uređaj */}
                            <TableCell align="right">Akcije</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {(sortedProducts && Array.isArray(sortedProducts) ? sortedProducts : []).map((product) => {
                            return (
                                <TableRow key={product.id}>
                                    <TableCell>{product.naziv_artikla}</TableCell>
                                    <TableCell align="right">{parseFloat(product.mpc).toFixed(2) || "0.00"} €</TableCell>
                                    <TableCell align="right">{parseFloat(product.vpc).toFixed(2) || "0.00"} €</TableCell>
                                    <TableCell align="right">{product.tip_artikla_naziv || "Nepoznato"}</TableCell>
                                    <TableCell align="right">{product.device_naziv || "Nepoznato"}</TableCell> {/* Prikaz naziva uređaja */}
                                    <TableCell align="right">
                                        <Button variant="outlined" color="primary" startIcon={<EditIcon />} onClick={() => handleEditOpen(product)}>
                                            Uredi
                                        </Button>
                                        <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => handleConfirmDeleteOpen(product)} style={{ marginLeft: '10px' }}>
                                            Obriši
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={open} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <h2>{editMode ? 'Uredi proizvod' : 'Dodaj proizvod'}</h2>
                    <TextField
                        label="Naziv proizvoda"
                        name="naziv"
                        fullWidth
                        value={newProduct.naziv}
                        onChange={(e) => setNewProduct({ ...newProduct, naziv: e.target.value })}
                        style={{ marginBottom: '20px' }}
                    />
                    <TextField
                        label="MPC"
                        name="mpc"
                        type="number"
                        fullWidth
                        value={newProduct.mpc}
                        onChange={(e) => setNewProduct({ ...newProduct, mpc: e.target.value })}
                        style={{ marginBottom: '20px' }}
                    />
                    <TextField
                        select
                        label="Tip Artikla"
                        name="tip_artikla_id"
                        fullWidth
                        value={newProduct.tip_artikla_id}
                        onChange={(e) => setNewProduct({ ...newProduct, tip_artikla_id: e.target.value })}
                        style={{ marginBottom: '20px' }}
                    >
                        {tipoviArtikla.length > 0 ? (
                            tipoviArtikla.map((tip) => (
                                <MenuItem key={tip.id} value={tip.id}>
                                    {tip.naziv}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled>Nema dostupnih tipova artikala</MenuItem>
                        )}
                    </TextField>

                    <TextField
                        select
                        label="Uređaj"
                        name="device_id"
                        fullWidth
                        value={newProduct.device_id}
                        onChange={(e) => setNewProduct({ ...newProduct, device_id: e.target.value })}
                        style={{ marginBottom: '20px' }}
                    >
                        {devices.length > 0 ? (
                            devices.map((device) => (
                                <MenuItem key={device.id} value={device.id}>
                                    {device.device}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled>Nema dostupnih uređaja</MenuItem>
                        )}
                    </TextField>
                    <Button variant="contained" color="primary" onClick={editMode ? handleEditProduct : handleAddProduct}>
                        {editMode ? 'Spremi' : 'Dodaj'}
                    </Button>
                </Box>
            </Modal>

            {/* Dialog za potvrdu brisanja */}
            <Dialog
                open={openConfirmDelete}
                onClose={handleConfirmDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Potvrda brisanja"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Jeste li sigurni da želite obrisati proizvod "{productToDelete?.naziv_artikla}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDeleteClose} color="primary">
                        Odustani
                    </Button>
                    <Button onClick={handleDeleteProduct} color="error" autoFocus>
                        Obriši
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Proizvodi;
