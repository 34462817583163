import React from "react";

const Storno = () => {

    return (
        <div>
            Storno
        </div>
    );

};

export default Storno;