import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Checkbox, Radio, RadioGroup, FormControlLabel, Box, TextField, TableSortLabel } from '@mui/material';
import Ispis from './Ispis';
import { useAuth } from '../AuthContext';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PrintIcon from '@mui/icons-material/Print';
import PaymentsIcon from '@mui/icons-material/Payments';

// Styles for the modal
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 700,
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Blagajna() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { user } = useAuth();
    const [racun, setRacun] = useState([]);
    const [open, setOpen] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [openPaymentModal, setOpenPaymentModal] = useState(false); // Držimo status za otvaranje/zatvaranje modalnog prozora za plaćanje
    const [nacinPlacanja, setNacinPlacanja] = useState('Gotovina');

    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]); // Čuvamo odabrane proizvode
    const [searchTerm, setSearchTerm] = useState(''); // Držimo vrijednost za pretragu
    const [orderDirection, setOrderDirection] = useState('asc');

    // Funkcija za pokretanje transakcije
    const handleStartTransaction = async () => {
        try {
            // Priprema podataka o ukupnom iznosu za transakciju
            const transactionData = {
                orderId: '02102024', // Zamijenite sa stvarnim OrderId
                amount: ukupnoSaPDV * 100, // Iznos u centima (Saferpay API očekuje vrijednost u centima)
            };

            const response = await fetch(`${apiUrl}/skripte/placanje.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(transactionData)
            });

            const data = await response.json();
            if (data.success) {
                console.log('Transakcija pokrenuta:', data);
                // Dodajte logiku za obradu uspješne transakcije, npr. prikaz statusa korisniku
            } else {
                console.error('Pogreška pri pokretanju transakcije:', data.error);
            }
        } catch (error) {
            console.error('Greška prilikom slanja zahtjeva za transakciju:', error);
        }
    };


    // Funkcija za ispis računa
    const handlePrint = () => {
        const imePrezime = `${user.ime} ${user.prezime}`;
        Ispis(racun, imePrezime, nacinPlacanja);
    };

    // Izračun ukupne cijene bez PDV-a
    const ukupnaCijenaBezPDV = Number(
        racun.reduce((total, item) => total + Number(item.cijena || 0), 0)
    ).toFixed(2);

    // Izračun PDV-a (25% od ukupne cijene bez PDV-a)
    const iznosPDV = (Number(ukupnaCijenaBezPDV) * 0.25).toFixed(2);

    // Izračun ukupne cijene s PDV-om
    const ukupnoSaPDV = (parseFloat(ukupnaCijenaBezPDV) + parseFloat(iznosPDV)).toFixed(2);



    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleEditOpen = (product) => {
        setSelectedProduct(product);
        setEditModal(true);
    };
    const handleEditClose = () => setEditModal(false);

    // Pretraga proizvoda
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Sortiranje po nazivu
    useEffect(() => {
        // Dohvati podatke o proizvodima iz API-ja
        fetch(`${apiUrl}/skripte/proizvodi.php`) // Promijenite URL u vaš stvarni API endpoint
            .then(response => response.json())
            .then(data => {
                setProducts(data.products);
            })
            .catch(error => console.error('Greška pri dohvatu podataka:', error));
    }, []);

    const handleSort = () => {
        const isAsc = orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setProducts(prevProducts =>
            prevProducts.sort((a, b) => {
                const nameA = (a.naziv_artikla || '').toLowerCase(); // Provjera da li postoji naziv_artikla
                const nameB = (b.naziv_artikla || '').toLowerCase(); // Provjera da li postoji naziv_artikla
                return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
            })
        );
    };

    const handleProductSelect = (productId) => {
        setSelectedProducts(prevSelected =>
            prevSelected.includes(productId)
                ? prevSelected.filter(id => id !== productId)
                : [...prevSelected, productId]
        );
    };

    // Dodavanje odabranih proizvoda
    const handleAddSelectedProducts = () => {
        const updatedRacun = [...racun];

        // Dodavanje svih odabranih proizvoda
        selectedProducts.forEach((selectedProductId) => {
            const selectedProduct = products.find(product => product.id === selectedProductId);

            if (!selectedProduct) return; // Preskoči ako proizvod ne postoji

            const itemIndex = updatedRacun.findIndex(item => item.id === selectedProduct.id);

            if (itemIndex !== -1) {
                // Ako proizvod već postoji u računu, povećaj količinu
                updatedRacun[itemIndex].kolicina += 1;
            } else {
                // Provjera postoji li vrijednost za vpc
                const cijenaProizvoda = selectedProduct.vpc ? selectedProduct.vpc : 0;

                // Dodaj novi proizvod s početnom količinom 1
                updatedRacun.push({
                    ...selectedProduct,
                    kolicina: 1,
                    popust: 0,
                    cijena: cijenaProizvoda
                });
            }
        });

        setRacun(updatedRacun);
        setSelectedProducts([]);
        handleClose(); // Zatvaramo modal nakon dodavanja
    };


    // Funkcija za brisanje proizvoda s računa
    const obrisiProizvod = (id) => {
        const updatedRacun = racun.filter((item) => item.id !== id);
        setRacun(updatedRacun);
    };

    // Funkcija za spremanje izmjena (količina i popust)
    const spremiIzmjene = () => {
        const updatedRacun = racun.map((item) => {
            if (item.id === selectedProduct.id) {
                // Izračunaj novu cijenu uz popust i količinu, zaokruži na 2 decimale
                const novaCijena = (item.vpc * selectedProduct.kolicina * (1 - selectedProduct.popust / 100)).toFixed(2);
                return { ...item, kolicina: selectedProduct.kolicina, popust: selectedProduct.popust, cijena: parseFloat(novaCijena) };
            }
            return item;
        });

        setRacun(updatedRacun);
        handleEditClose();
    };

    // Funkcija za postavljanje promjena količine i popusta
    const handleChange = (e) => {
        const { name, value } = e.target;
        const parsedValue = Math.max(0, parseFloat(value));
        setSelectedProduct({ ...selectedProduct, [name]: parsedValue });
    };

    // Otvaranje modalnog prozora za način plaćanja
    const handleOpenPaymentModal = () => {
        setOpenPaymentModal(true);
    };

    // Zatvaranje modalnog prozora za način plaćanja
    const handleClosePaymentModal = () => {
        setOpenPaymentModal(false);
    };

    // Izbor načina plaćanja
    const handlePaymentChange = (event) => {
        setNacinPlacanja(event.target.value);
    };

    return (
        <div>
            {/* Dugme za dodavanje proizvoda */}
            <Button variant="contained" color="primary" startIcon={<AddCircleIcon />} onClick={handleOpen}>
                Dodaj Proizvod
            </Button>

            <Button
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
                style={{ marginLeft: '10px' }}
                onClick={handlePrint}
            >
                Ispiši Račun
            </Button>

            {/* <Button
                variant="contained"
                color="secondary"
                startIcon={<PaymentsIcon />}
                onClick={handleStartTransaction} // Ovdje povezujete novu funkciju
                style={{ marginLeft: '10px' }}
            >
                Pokreni transakciju
            </Button> */}

            {/* Novi gumb za odabir načina plaćanja */}
            <Button
                variant="contained"
                color="primary"
                startIcon={<PaymentsIcon />}
                onClick={handleOpenPaymentModal}
                style={{ marginLeft: '10px' }}
            >
                Odaberi način plaćanja
            </Button>

            <h3>Račun br.</h3>
            <h4>Način plaćanja: {nacinPlacanja}</h4>

            {/* Glavna tablica za trenutni račun */}
            <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Proizvod</TableCell>
                            <TableCell>Cijena</TableCell>
                            <TableCell>Količina</TableCell>
                            <TableCell>Popust (%)</TableCell>
                            <TableCell>Ukupna cijena</TableCell>
                            <TableCell>Akcija</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {racun.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.naziv_artikla}</TableCell>
                                <TableCell>{Number(row.vpc || 0).toFixed(2)} €</TableCell>
                                <TableCell>{row.kolicina}</TableCell>
                                <TableCell>{row.popust}</TableCell>
                                <TableCell>{Number(row.cijena || 0).toFixed(2)} €</TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        startIcon={<EditIcon />}
                                        onClick={() => handleEditOpen(row)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color='error'
                                        startIcon={<DeleteIcon />}
                                        onClick={() => obrisiProizvod(row.id)}
                                        style={{ marginLeft: 10 }}
                                    >
                                        Obriši
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}

                        {/* Dodavanje dodatnih redova za ukupni iznos, PDV i ukupno sa PDV-om */}
                        <TableRow>
                            {/* Tekstovi u koloni Popust (%) */}
                            <TableCell colSpan={3}></TableCell>
                            <TableCell align="right">
                                <div>Ukupno bez PDV-a:</div>
                                <div>Iznos PDV-a (25%):</div>
                                <strong>Ukupno s PDV-om:</strong>
                            </TableCell>

                            {/* Iznosi u koloni Ukupna cijena */}
                            <TableCell>
                                <div>{ukupnaCijenaBezPDV} €</div>
                                <div>{iznosPDV} €</div>
                                <strong>{ukupnoSaPDV} €</strong>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal za dodavanje proizvoda */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <h2 id="product-modal-title">Odaberi Proizvod</h2>

                    {/* Search bar za pretragu */}
                    <TextField
                        label="Pretraga proizvoda"
                        variant="outlined"
                        fullWidth
                        value={searchTerm}
                        onChange={handleSearchChange}
                        style={{ marginBottom: '20px' }}
                    />

                    {/* Gumb za dodavanje odabranih proizvoda */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddSelectedProducts}
                        style={{ marginBottom: '20px', float: 'left' }}
                    >
                        Dodaj
                    </Button>

                    {/* Tablica za proizvode */}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Odaberi</TableCell>
                                    <TableCell sortDirection={orderDirection}>
                                        <TableSortLabel
                                            active
                                            direction={orderDirection}
                                            onClick={handleSort}
                                        >
                                            Proizvod
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">Cijena (€)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products
                                    .filter((product) =>
                                        product.naziv_artikla.toLowerCase().includes(searchTerm.toLowerCase()) // Filtriraj prema `searchTerm`
                                    )
                                    .map((product) => (
                                        <TableRow key={product.id}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedProducts.includes(product.id)}
                                                    onChange={() => handleProductSelect(product.id)}
                                                />
                                            </TableCell>
                                            <TableCell>{product.naziv_artikla}</TableCell>
                                            <TableCell align="right">{Number(product.mpc || 0).toFixed(2)} €</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>

            {/* Modal za uređivanje proizvoda */}
            <Modal
                open={editModal}
                onClose={handleEditClose}
                aria-labelledby="edit-modal-title"
                aria-describedby="edit-modal-description"
            >
                <Box sx={modalStyle}>
                    <h2 id="edit-modal-title">Uredite Proizvod</h2>
                    {selectedProduct && (
                        <div>
                            <TextField
                                label="Količina"
                                type="number"
                                name="kolicina"
                                value={selectedProduct.kolicina}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                inputProps={{ min: 1 }}
                            />
                            <TextField
                                label="Popust (%)"
                                type="number"
                                name="popust"
                                value={selectedProduct.popust}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                inputProps={{ min: 0 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={spremiIzmjene}
                                style={{ marginTop: 20 }}
                            >
                                Spremi
                            </Button>
                        </div>
                    )}
                </Box>
            </Modal>

            {/* Modal za odabir načina plaćanja */}
            <Modal
                open={openPaymentModal}
                onClose={handleClosePaymentModal}
                aria-labelledby="payment-modal-title"
                aria-describedby="payment-modal-description">
                <Box sx={modalStyle}>
                    <div>
                        <h2 id="payment-modal-title">Odaberi način plaćanja</h2>
                        <RadioGroup aria-label="nacin-placanja" name="nacin-placanja" value={nacinPlacanja} onChange={handlePaymentChange}>
                            <FormControlLabel value="Gotovina" control={<Radio />} label="Gotovina" />
                            <FormControlLabel value="Kartica" control={<Radio />} label="Kartica" />
                            <FormControlLabel value="R1" control={<Radio />} label="R1" />
                        </RadioGroup>
                        <Button onClick={handleClosePaymentModal} variant="contained" color="primary" style={{ marginTop: 20 }}>
                            Spremi
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default Blagajna;
