import React, { useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, BarElement, CategoryScale, LinearScale, PointElement, Legend } from 'chart.js';
import { Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';

ChartJS.register(LineElement, BarElement, CategoryScale, LinearScale, PointElement, Legend);

const LineChart = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [chartData, setChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [interval, setInterval] = useState('week');
  const [error, setError] = useState(null);

  const colorPalette = [
    '#f62b00', '#3357FF', '#00d8c7', '#FF9933',
    '#33FFF5', '#8D33FF', '#f62b00', '#00d8c7'
  ];

  const fetchData = () => {
    fetch(`${apiUrl}/skripte/statistika.php?interval=${interval}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Podaci iz API-ja:", data);

        // Log svih 'datum' vrednosti koje stižu iz API-ja da proverimo njihovo postojanje
        data.forEach(item => console.log("Datum iz API-ja:", item.datum));

        const labels = [...new Set(data.map(item => item.datum))];
        const zaposlenici = [...new Set(data.map(item => item.zaposlenik))];

        // Kreiraj datasets za linijski grafikon
        const lineDatasets = zaposlenici.map((zaposlenik, index) => {
          const zaposlenikData = data.filter(item => item.zaposlenik === zaposlenik);
          return {
            label: zaposlenik,
            data: labels.map(label => {
              const found = zaposlenikData.find(item => item.datum === label);
              return found ? found.broj_termina : 0;
            }),
            fill: false,
            borderColor: colorPalette[index % colorPalette.length],
            tension: 0.1
          };
        });

        console.log("Labels za linijski grafikon:", labels);
        console.log("Datasets za linijski grafikon:", lineDatasets);

        setChartData({
          labels: labels,
          datasets: lineDatasets,
        });

        const barData = zaposlenici.map(zaposlenik => {
          const zaposlenikData = data.filter(item => item.zaposlenik === zaposlenik);
          const totalSales = zaposlenikData.reduce((sum, item) => sum + (item.ukupna_prodaja || 0), 0);
          return totalSales;
        });

        setBarChartData({
          labels: zaposlenici,
          datasets: [{
            label: 'Prodaja (€)',
            data: barData,
            backgroundColor: colorPalette.slice(0, zaposlenici.length),
          }]
        });
      })
      .catch((error) => {
        console.error('Greška prilikom dohvaćanja podataka:', error);
        setError('Greška prilikom dohvaćanja podataka');
      });
  };


  useEffect(() => {
    fetchData();
  }, [interval]);

  const lineOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: interval === 'year' ? 'Mjesec' : 'Datum'
        },
      },
      y: {
        title: {
          display: true,
          text: 'Broj termina'
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

  const barOptions = {
    scales: {
      y: {
        title: {
          display: true,
          text: 'Prodaja (€)'
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

  return (
    <div>
      <FormControl style={{ marginBottom: '20px' }}>
        <InputLabel>Interval</InputLabel>
        <Select
          value={interval}
          onChange={(e) => setInterval(e.target.value)}
        >
          <MenuItem value="week">Tjedan (samo ovaj tjedan)</MenuItem>
          <MenuItem value="month">Mjesec (samo ovaj mjesec)</MenuItem>
          <MenuItem value="year">Godina (prikaz po mjesecima)</MenuItem>
        </Select>
      </FormControl>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          {error ? (
            <p>{error}</p>
          ) : chartData ? (
            <Line data={chartData} options={lineOptions} />
          ) : (
            <p>Učitavanje podataka...</p>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {barChartData ? (
            <Bar data={barChartData} options={barOptions} />
          ) : (
            <p>Učitavanje podataka...</p>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default LineChart;
