import React, { useState } from 'react';
import { useAuth } from '../components/AuthContext';
import { ToastContainer } from 'react-toastify';
import Navbar from '../components/navbar';
import Kontrola from '../components/blagajna/Kontrola';
import Obracun from '../components/blagajna/Obracun';
import Racun from '../components/blagajna/Racun';
import Storno from '../components/blagajna/Storno';

const Blagajna = () => {
    const { user } = useAuth();
    const [activeComponent, setActiveComponent] = useState('Racun');

    // Funkcija za renderovanje odgovarajuće komponente
    const renderComponent = () => {
        switch (activeComponent) {
            case 'Racun':
                return <Racun />;
            case 'Storno':
                return <Storno />;
            case 'Kontrola':
                return <Kontrola />;
            case 'Obracun':
                return <Obracun />;
            default:
                return <Racun />;
        }
    };

    return (
        <div>
            {user && <Navbar />}
            <main className="community-main">
                <div className="content">
                    <nav className="admin-sidebar">

                        <ul>
                            <h3>Menu</h3>
                            <li>
                                <button onClick={() => setActiveComponent('Racun')}>
                                    Račun
                                </button>
                            </li>
                            <li>
                                <button onClick={() => setActiveComponent('Storno')}>
                                    Storno
                                </button>
                            </li>
                            <li>
                                <button onClick={() => setActiveComponent('Kontrola')}>
                                    Kontrola
                                </button>
                            </li>
                            <li>
                                <button onClick={() => setActiveComponent('Obracun')}>
                                    Obračun
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="content">
                    {renderComponent()}
                </div>
            </main>
            <ToastContainer />
        </div>
    );
};

export default Blagajna;
